<template>
  <div class="bg-gray-100 pt-8 pb-16 min-h-screen mx-4">
    <article>
      <header class="text-center mb-8">
        <h1 class="text-primary text-3xl leading-none">
          {{ story.title }}
        </h1>
      </header>
      <div class="p-6 pt-2 w-full max-w-3xl mx-auto relative bg-white shadow-lg rounded break-words">
        <div
          class="markdown"
          v-html="body"
        ></div>
      </div>
    </article>
  </div>
</template>

<script>
import marked from 'marked'

export default {
  props: {
    story: {
      type: Object,
      default: null
    }
  },

  computed: {
    /**
     * @return {String}
    */
    body() {
      return marked(this.story.body)
    },

    /**
     * TEMP - REMOVE
    */
    isFaq() {
      return this.$route.name === 'faq'
    }
  }
}
</script>
